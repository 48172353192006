import * as type from './environment.type';

export const environment: type.ObjEnvironment = {
  production: true,
  version: '1.14.0',
  branchVersion: '@@branchversion',
  apiCoreUrl: 'https://dna-ww-int.thecosmetrics.com/core',
  apiSharedUrl: 'https://dna-ww-int.thecosmetrics.com/shared',
  apiLibrariesUrl: 'https://dna-ww-int.thecosmetrics.com/libraries',
  apiApptalkUrl: 'https://dna-ww-int.thecosmetrics.com/apptalk',
  apiStudiesUrl: 'https://dna-ww-int.thecosmetrics.com/studies',
  apiAnswersUrl: 'https://dna-ww-int.thecosmetrics.com/answers',
  publicIpEndpoint: 'https://api.ipify.org?format=json',
  msal: {
    clientId: '84560ea9-8297-4f24-81f9-3a8b83bb4bf6',
    authority: 'https://login.microsoftonline.com/e4e1abd9-eac7-4a71-ab52-da5c998aa7ba',
    redirectUri: 'https://digitexpertstudio-int.thecosmetrics.com/',
    postLogoutRedirectUri: 'https://digitexpertstudio-int.thecosmetrics.com/'
  },
  server_url: function (): string {
    return 'https://digitexpertstudio-int.thecosmetrics.com';
  },
  server_url_studio: function (): string {
    return this.server_url();
  },
  mobile_url: function (): string {
    return 'https://digitexpertmobile-int.thecosmetrics.com/';
  },
  name: function (): string {
    return 'int';
  },
  namehttp: function (): string {
    return 'int';
  },
  bridge_url: function (): string {
    return 'https://bridge-ri.loreal.wans/Bridge/default.aspx?ACTION=MODIFY&REQ_NUMBER=';
  },
  milor_url: function (): string {
    return 'https://oa.preprod.nuxeocloud.com/nuxeo/ui/#!/doc/';
  },
  appInsights: {
    instrumentationKey: function (): string {
      return 'b8037e0a-db9a-4f03-a829-66265b178838';
    }
  },
  synergy_url: function (): string {
    return 'https://qua-synergy-ri.loreal.wans/Synergy/Demand/Edit/';
  },
  actiview_url: function (): string {
    return 'http://qua-ficheeval-ri.loreal.wans/FicheEval/SynthesisReport/Index/';
  },
  pfc_base_url: function () {
    return 'https://qua-performancecenter-ri.loreal.wans/shell/'
  }
};
