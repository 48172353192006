
import { mergeMap, tap, finalize, takeUntil, catchError, map } from 'rxjs/operators';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from './../../../../../../environments/environment';

import * as _ from 'lodash';
import { Observable, of, Subject, throwError } from 'rxjs';

import {
  Campaign,
  KeyValue,
  Method,
  ProtocolSkin,
  TypeApplicationArea,
  TypeApplicationMode,
  TypeMethod,
  TypeMetier,
  ProductsType,
  StudyType,
  TypeTest,
  Protocol,
  CampaignProtocol,
  CampaignStates,
  ActiviewStudyAxe,
  CampaignInfosGen,
  ActiviewLang
} from '../../../../../types';
import { CampaignService } from '../../../../campaigns.service';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { ErrorManagerService } from '../../../../../shared/services/errorManager.service';
import { ReferenceTypeService } from '../../../../../shared/services/reference-type.service';
import { UtilService } from '../../../../../shared/services/util.service';
import { HttpRestService } from '@src/app/shared/services/httpRest.service';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-protocol-skin',
  templateUrl: './protocol-skin.component.html',
  styleUrls: ['./protocol-skin.component.less']
})
export class ProtocolSkinComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() campaignProtocol: CampaignProtocol;
  @Input() publishedTemplate: boolean;

  protocol: Protocol;
  metierName: TypeMetier;
  applicationAreas: TypeApplicationArea[];
  applicationModes: TypeApplicationMode[];
  isChangedProtocol: boolean = false;
  protocol_methods: TypeMethod[];
  methods: Method[];
  studyTypes: StudyType[];
  originalForm: FormGroup;
  protocolForm: FormGroup;
  researchAxes: ActiviewStudyAxe[];
  showSpinner: boolean = true;
  studies: ProductsType[];
  tests: TypeTest[];
  submitted: boolean = false;
  campaignStates: typeof CampaignStates = CampaignStates;
  isDisabled: boolean = false;
  protocolMethodsNoodles: boolean;
  error: any;
  campaign: CampaignInfosGen;
  languageMap = {
    english: ActiviewLang.english,
    french: ActiviewLang.french,
    japanese: ActiviewLang.japanese,
    chinese: ActiviewLang.chinese,
    portuguese: ActiviewLang.portuguese,
    spanish: ActiviewLang.spanish
  };
  currentLanguage: string;
  hub: string;
  initTime = performance.now();

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private campaignService: CampaignService,
    private DNATranslate: DNATranslateService,
    private errorManagerService: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private formBuilder: FormBuilder,
    private referenceTypeService: ReferenceTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private httpRestService: HttpRestService
  ) { }

  ngOnInit() {
    this.showSpinner = true;
    const idCampaign = this.route.parent.snapshot.paramMap.get('idCampaign');
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.campaignService.getCampaignInfoGen(idCampaign).pipe(
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      map((campaignInfoGen: CampaignInfosGen) => {
        this.campaign = campaignInfoGen;
        this.hub = campaignInfoGen?.hub;
        if (_.isUndefined(this.campaignProtocol.protocol)) this.campaignProtocol.protocol = new ProtocolSkin();
        this.protocolMethodsNoodles = _.get(this.campaignProtocol, 'protocol.protocol_methods', []).some(item => item.key === "NOODLES");
        this.metierName = _.get(this.campaignProtocol, 'metier.name', undefined);
        this.isDisabled = this.utilService.isNotEditable(this.campaignProtocol.state);
        this.protocolForm = this.createProtocolForm(<ProtocolSkin>this.campaignProtocol.protocol);
        this.originalForm = _.cloneDeep(this.protocolForm);

        this.getProtocolObjectsAndSetForm().pipe(
          takeUntil(this.destroy$))
          .subscribe(
            () => { },
            error => this.errorManagerService.catchError(error)
          );

        this.DNATranslate.onLangChange().pipe(
          mergeMap(() => this.getProtocolObjectsAndSetForm()),
          takeUntil(this.destroy$),)
          .subscribe();

        this.onChanges();
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }
  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Protocol Skin', '', performance.now() - this.initTime, templateUrl);
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  compareKeyValue(c1: KeyValue, c2: KeyValue): boolean {
    return c2 ? c1.key === c2.key : false;
  }

  compareCode(c1: any, c2: any): boolean {
    return c2 ? c1.code === c2.code : false;
  }


  compareRadio(c1: KeyValue, fieldName: string) {
    let c2 = this.protocolForm.get(fieldName).value;
    return this.compareKeyValue(c1, c2);
  }

  createProtocolForm(protocol: ProtocolSkin): FormGroup {
    return this.formBuilder.group({
      applicationAreas: [{ value: protocol.applicationAreas, disabled: this.publishedTemplate }],
      applicators: [{ value: protocol.applicators, disabled: this.publishedTemplate }],
      description: [{ value: protocol.description, disabled: this.publishedTemplate }],
      protocol_methods: [{ value: protocol.protocol_methods, disabled: this.publishedTemplate }],
      method: [{ value: protocol.method, disabled: this.publishedTemplate }],
      researchAxes: [{ value: protocol.researchAxes ? protocol.researchAxes : {}, disabled: this.publishedTemplate }],
      studies: [{ value: protocol.studies, disabled: this.publishedTemplate }],
      studyType: [
        { value: protocol.studyType ? protocol.studyType : {}, disabled: this.publishedTemplate },
        this.conditionalRequiredValidator(this.campaign.actiview ? !!this.campaign.actiview.activityNumber : false)
      ],
      test: [{ value: protocol.test, disabled: this.publishedTemplate }],
      timepoints: [{ value: protocol.timepoints, disabled: this.publishedTemplate }],
      estimatedContribution: [{ value: protocol.estimatedContribution, disabled: this.isDisabled }, this.conditionalRequiredValidator(this.campaign.actiview ? !!this.campaign.actiview.activityNumber : false)],
      timepointsInterval: this.formBuilder.array(this.initTimepointsIntervalArray(protocol.timepointsInterval))
    });
  }

  formatProtocol(protocol: any): ProtocolSkin {
    protocol.timepointsInterval = protocol.timepointsInterval.map(timepoint => timepoint.value.replace(/\s{1,}/g, "").toUpperCase());
    return protocol;
  }

  private getProtocolObjectsAndSetForm() {
    return this.referenceTypeService.getApplicationAreas(this.metierName).pipe(
      tap((applicationAreas: TypeApplicationArea[]) => this.applicationAreas = this.utilService.sortArrayOfKeyValueObjects(applicationAreas)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get("applicationAreas"))),
      mergeMap(() => this.referenceTypeService.getApplicationModes(this.metierName)),
      tap((applicationModes: TypeApplicationMode[]) => this.applicationModes = this.utilService.sortArrayOfKeyValueObjects(applicationModes)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get("applicators"))),
      mergeMap(() => this.referenceTypeService.getProtocolMethods(this.metierName)),
      tap((protocol_methods: TypeMethod[]) => this.protocol_methods = this.utilService.sortArrayOfKeyValueObjects(protocol_methods)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get("protocol_methods"))),
      mergeMap(() => this.campaignService.getActiviewMethods()),// this.referenceTypeService.getMethods()),
      mergeMap((methods) => this.referenceTypeService.dataTranslations(methods, 'key', 'value')),
      tap((methods: Method[]) => this.methods = this.utilService.sortArrayOfKeyValueObjects(methods)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get("method"))),
      mergeMap(() => this.referenceTypeService.getStudies(this.metierName)),
      tap((studies: ProductsType[]) => this.studies = this.utilService.sortArrayOfKeyValueObjects(studies)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get("studies"))),
      mergeMap(() => this.campaignService.getActiviewTypes()),//this.referenceTypeService.getStudyTypes()),
      tap((studyTypes: StudyType[]) => this.studyTypes = studyTypes.filter((studyType) => studyType.is_active)),
      mergeMap(() => this.httpRestService.getResearchAxesData()),
      tap((researchAxes: any) => this.researchAxes = researchAxes.researchAxes),
      mergeMap(() => this.referenceTypeService.getTests(this.metierName)),
      tap((tests: TypeTest[]) => this.tests = this.utilService.sortArrayOfKeyValueObjects(tests)),
      mergeMap(() => this.translateAndPatchProtocolFormObject(this.protocolForm.get("test"))),
      tap(() => this.activiewSynchro(this.campaign)),
      tap(() => this.initActiviewData(this.campaignProtocol, this.researchAxes, this.studyTypes, this.protocolForm)),
      tap(() => this.initProtocolFormAxeResearch(this.campaignProtocol, this.researchAxes, this.protocolForm))
    )
  }

  /**
 * Item 23596 - Initialiser les valeurs d'Actiview dans le protocole
 * @param campaignProtocol
 */
  initActiviewData(campaignProtocol: CampaignProtocol, researchAxes: ActiviewStudyAxe[], studyTypes: StudyType[], protocolForm: FormGroup) {
    this.initAxeResearch(campaignProtocol, researchAxes, protocolForm);
    this.initStudyTypes(campaignProtocol, studyTypes, protocolForm);
  }

  initProtocolFormAxeResearch(campaignProtocol: CampaignProtocol, researchAxes: ActiviewStudyAxe[] = [], protocolForm: FormGroup) {
    if (campaignProtocol.protocol && campaignProtocol.protocol.researchAxes && Object.keys(campaignProtocol.protocol.researchAxes).length != 0) {
      const axe = researchAxes.find(a => a.code == campaignProtocol.protocol.researchAxes.code);
      if (!axe) {
        protocolForm.controls.researchAxes.setValue({});
        this.isChangedProtocol = true;
      }
    }
  }

  initAxeResearch(campaignProtocol: CampaignProtocol, researchAxes: ActiviewStudyAxe[] = [], protocolForm: FormGroup) {
    if (!campaignProtocol.protocol.researchAxes && _.get(campaignProtocol, 'actiview.study_research_axis_id', false)) {
      const axe = researchAxes.find(a => a.code == campaignProtocol.actiview.study_research_axis_id);
      if (axe) {
        protocolForm.controls['researchAxes'].setValue(axe);
        this.isChangedProtocol = true;
      }
    }
  }
  initStudyTypes(campaignProtocol: CampaignProtocol, studyTypes: StudyType[] = [], protocolForm: FormGroup) {
    if (!campaignProtocol.protocol.studyType && _.get(campaignProtocol, 'actiview.studyType', false)) {
      const studyType = studyTypes.find(a => a.code == campaignProtocol.actiview.studyType);
      if (studyType) {
        protocolForm.controls['studyType'].setValue(studyType);
        this.isChangedProtocol = true;
      }
    }
  }

  onCancel() {
    this.protocolForm.reset(this.originalForm.value);
    this.submitted = false;
  }

  onChanges(): void {
    this.protocolForm.valueChanges.subscribe(() => {
      this.isChangedProtocol = this.utilService.isDifferent(this.protocolForm.value, this.originalForm.value);
    });
  }

  onSubmit(form: FormGroup) {
    this.submitted = true;
    const studyTypeExists = this.studyTypes.some(studyType => studyType.code === form.get('studyType').value.code);
    const researchAxisExists = this.researchAxes.some(axe => axe.code == form.get('researchAxes').value.code);

    if (studyTypeExists) {
      const matchedStudyType = this.studyTypes.find(studyType => studyType.code === form.get('studyType').value.code);
      form.get('studyType').setValue(matchedStudyType);
    }

    if ((!studyTypeExists || (form.get('studyType').value && !form.get('studyType').value.is_active)) && this.campaign?.actiview?.activityNumber) {
      this.errorManagerService.displayMessage('ERROR_INVALID_OR_INACTIVE_STUDY_TYPE', 'danger');
      return;
    }

    if (!form.get('estimatedContribution').value && this.campaign?.actiview?.activityNumber) {
      this.errorManagerService.displayMessage('ERROR_INVALID_ESTIMATED_CONTRIBUTION', 'danger');
      return;
    }

    if (!researchAxisExists && form.get('researchAxes').value.code) {
      this.errorManagerService.displayMessage('ERROR_INVALID_RESEARCH_AXIS', 'danger');
      return;
    }

    if (!form.invalid) {
      this.save(form);
    } else {
      this.errorManagerService.displayMessage("ON_ERROR_FORM", "danger");
    }
  }

  private save(form, sync?) {
    this.showSpinner = true;
    Object.assign(this.campaignProtocol.protocol, this.formatProtocol(_.cloneDeep(form.value)));

    this.campaignService.putCampaignProtocol(this.campaignProtocol.id, this.campaignProtocol.protocol).pipe(
      takeUntil(this.destroy$),
      finalize(() => this.showSpinner = false),)
      .subscribe(
        () => {
          if (!sync) {
            this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE');
            this.router.navigate(['campaigns', this.campaignProtocol.id, 'edit', 'workflows']);
          } else {
            this.originalForm = _.cloneDeep(this.protocolForm);
            this.showSpinner = false;
            this.errorManagerService.displayMessage('ON_SYNCHRO_PROTOCOL_ACTIVIEW');
          }
        },
        error => this.errorManagerService.catchError(error)
      );
  }

  private translateAndPatchProtocolFormObject(protocolFormObject: AbstractControl): Observable<KeyValue[] | KeyValue> {
    let myArray = _.isArray(protocolFormObject.value) ? protocolFormObject.value : [protocolFormObject.value];
    return this.referenceTypeService.keyValueTranslations(myArray).pipe(
      tap(formObjectTranslatedArray => protocolFormObject.patchValue(_.isArray(protocolFormObject.value) ? formObjectTranslatedArray : formObjectTranslatedArray[0])))
  }

  /********** TIMEPOINTS INTERVAL **********/

  get timepointsIntervalArray(): FormArray {
    return this.protocolForm.get("timepointsInterval") as FormArray;
  }

  addItem(timepoints: number): void {
    if (timepoints < 10) {
      this.timepointsIntervalArray.push(this.getTimepointsIntervalFormGroup());
      this.protocolForm.patchValue({ timepoints: timepoints + 1 });
    }
  }

  getTimepointsIntervalFormGroup(value: string = ""): FormGroup {
    return this.formBuilder.group({
      value: [value]
    });
  }

  initTimepointsIntervalArray(timepointsInterval: string[]) {
    let array = [];
    if (!Array.isArray(timepointsInterval)) {
      timepointsInterval = [timepointsInterval]
    }
    timepointsInterval.forEach((timepoint: string) => {
      array.push(this.getTimepointsIntervalFormGroup(timepoint));
    });
    return array;
  }

  removeTimepointsInterval(index: number, timepoints: number) {
    this.timepointsIntervalArray.removeAt(index);
    this.protocolForm.patchValue({ timepoints: timepoints - 1 });
  }

  getStatus(key) {
    return this.protocolForm.controls[key].invalid && this.submitted;
  }

  conditionalRequiredValidator(condition: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (condition && (!control.value || _.isEqual(control.value, {}))) {
        return { required: true };
      }
      return null;
    };
  }

  activiewSynchro(campaign: any) {
    if (campaign.actiview?.activityNumber) {
      return this.httpRestService.getActiview(campaign.actiview.activityNumber, true).pipe(
        map(actiview => {
          switch (true) {
            case !this.protocolForm.get('studyType').value?.name:
              this.protocolForm.patchValue({
                studyType: this.studyTypes.find(el => el.name.en === actiview.input.studyType || el.name.fr === actiview.input.studyType)
              });
              break;
              
            case !this.protocolForm.get('estimatedContribution').value:
              this.protocolForm.patchValue({
                estimatedContribution: actiview.output?.estimated_contribution
              });
              break;
            
              case !this.protocolForm.get('method').value:
                this.protocolForm.patchValue({
                  method: actiview.output?.methods_id
                });
                break;
          }
        }),
        catchError((error) => {
          console.error('Error fetching actiview:', error);
          return of(null);
        }),
        finalize(() => {
          this.save(this.protocolForm, true);
        })
      ).subscribe();
    } else {
      this.showSpinner = false;
    }
  }
}
